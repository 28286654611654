import { FreyrEventPayload } from '~/interfaces/fte.interface';

const sendFreyrEvent = (event: string, eventPayload: FreyrEventPayload) => {
  setTimeout(() => {
    window.freyr.cmd.push(() => {
      window.freyr.pushAndUpdate(event, eventPayload);
    });
  }, 0);
};

export default sendFreyrEvent;
