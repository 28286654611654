/* eslint-disable import/prefer-default-export */

export const getCurrentDomain = () => {
  if (typeof window !== 'undefined') {
    const host = window?.location.host.split('.');

    if (host[0] === 'local') host.shift();
    if (host[0] === 'www' || host[0] === 'global') host.shift();

    return host.join('.');
  }

  return 'localhost';
};
