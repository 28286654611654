/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import { getCookie, setCookie } from '~/components/utils/cookies';
import { getCurrentDomain } from '~/components/utils/domains';
import sendFreyrEvent from '~/components/utils/freyrEvents';
import { IRegionRedirectBanner } from '~/interfaces/components/RegionRedirectBanner';
import '~/interfaces/fte.interface';

const POP_UP_DISPLAYED_COOKIE_NAME = 'SLICE_popup_displayed';
const DEFINED_COUNTRY_CODE_COOKIE_NAME = 'FTR_User_Defined_Country_Code';
const COUNTRY_CODE_COOKIE_NAME = 'FTR_Country_Code';
const DEFINED_COUNTRY_HOMEPAGE_COOKIE_NAME = 'FTR_User_Defined_Homepage';
const DEFINED_COUNTRY_HOMEPAGE_COOKIE_LINK_NAME = 'FTR_User_Defined_Homepage_Link';

const RegionRedirectBanner: React.FC<IRegionRedirectBanner> = ({
  currentEdition,
  translations,
}) => {
  const [bannerVisibility, setVisibility] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  if (isFirstRender) {
    return null;
  }

  const hasUserInteracted = Boolean(getCookie(POP_UP_DISPLAYED_COOKIE_NAME));
  const currentCountryCode = getCookie(COUNTRY_CODE_COOKIE_NAME);
  // Certain special cases defining when it shouldn't show where it otherwise would
  const isSpecialCase = () => currentEdition === 'ES_US' && currentCountryCode === 'US';

  // If the current country code is not in the array, it is because the edition doesn't exist.
  const rightEdition = translations.find(
    (translation) => translation.locale === currentCountryCode,
  );

  /**
   * This component should not render when:
   * 1. COUNTRY_CODE_COOKIE_NAME doesn't exist.
   * 2. If there is no version for the current country code.
   * 3. POP_UP_DISPLAYED_COOKIE_NAME has been set.
   * 4, currentEdition is equals to currentCountryCode.
   * 5. SpecialCase: If currentEdition is ES_US and current code is US
   */

  const shouldNotRender =
    !currentCountryCode ||
    rightEdition === undefined ||
    hasUserInteracted ||
    currentCountryCode === currentEdition ||
    isSpecialCase() ||
    !bannerVisibility;

  if (shouldNotRender) {
    return null;
  }

  const currentDomain: string = getCurrentDomain();

  const { translatedText, locale, homepageLink } = rightEdition;

  const sendRegionRedirectFreyrEvent = (action: string, className: string, container: string) => {
    const match = translatedText.match(/<a [^>]*>(.*?)<\/a>/);
    const textFromLink = match ? match[1] : '';

    sendFreyrEvent('elementInteraction', {
      element: {
        action,
        id: 'region-redirect-banner',
        class: className,
        name: null,
        text: textFromLink.trim(),
        label: null,
        container,
        url: document.URL,
      },
    });
  };

  sendRegionRedirectFreyrEvent('appear', 'region_redirect_banner', 'DIV');

  const handleRedirectClick = () => {
    setVisibility(false);

    if (document) {
      setCookie({
        name: DEFINED_COUNTRY_CODE_COOKIE_NAME,
        value: locale,
        path: '/',
        domain: currentDomain,
      });

      setCookie({
        name: POP_UP_DISPLAYED_COOKIE_NAME,
        value: 'true',
        path: '/',
        domain: currentDomain,
      });

      setCookie({
        name: DEFINED_COUNTRY_HOMEPAGE_COOKIE_NAME,
        value: `${window?.location.protocol}//${window?.location.host}/${homepageLink}`,
        path: '/',
      });
      setCookie({
        name: DEFINED_COUNTRY_HOMEPAGE_COOKIE_LINK_NAME,
        value: `${window?.location.protocol}//${window?.location.host}/${homepageLink}`,
        path: '/',
      });
    }
    sendRegionRedirectFreyrEvent('click', 'region-redirect-banner__text', 'SPAN');
  };

  const handleCloseClick = () => {
    setVisibility(false);
    if (document) {
      setCookie({
        name: POP_UP_DISPLAYED_COOKIE_NAME,
        value: 'true',
        path: '/',
        domain: currentDomain,
      });
    }
    sendRegionRedirectFreyrEvent('close', 'region-redirect-banner__close', 'SVG');
  };

  return (
    <div className="region_redirect_banner" data-nosnippet>
      {translatedText && (
        <span
          onClick={() => handleRedirectClick()}
          className="region-redirect-banner__text"
          dangerouslySetInnerHTML={{ __html: translatedText }}
        />
      )}
      <svg
        className="region-redirect-banner__close"
        onClick={() => handleCloseClick()}
        viewBox="0 0 384 512"
        role="button"
      >
        <path d="M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z" />
      </svg>
    </div>
  );
};

export default RegionRedirectBanner;
