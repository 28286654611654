const isLocalhost = () =>
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const setCookie = ({
  name,
  value,
  expireDate,
  secure,
  path,
  domain,
}: {
  name: string;
  value: string;
  expireDate?: Date;
  secure?: boolean;
  path?: string;
  domain?: string;
}) => {
  const expireString = expireDate ? ` Expires=${expireDate?.toUTCString()};` : '';
  const pathString = path ? ` Path=${path};` : '';
  const domainString = domain && !isLocalhost() ? ` Domain=${domain};` : '';
  const secureString = secure ? ' Secure;' : '';

  document.cookie = `${name}=${value};${expireString}${pathString}${domainString}${secureString}`;
};

export const getCookie = (desiredCookie: string): string | null => {
  if (typeof document !== 'undefined') {
    const match = document.cookie.match(`(^|;) ?${desiredCookie}=([^;]*)(;|$)`);
    return match ? match[2] : null;
  }

  return null;
};
